import React, { Component } from 'react'
import { Grid, 
    Menu, 
    Segment, 
    SegmentGroup,
    Header,
    Image,
 } from 'semantic-ui-react'
 import logo from './../resources/jorgecaumont.jpg'

export default class ColumnsPage extends Component {
    constructor() {
        super();
        this.state = { 
            activeItem: 0,
            posts: [],    
        }
    }

    componentDidMount() { this.fetchColumns(); }

    fetchColumns() {
        let columnsURL = "http://api.jorgecaumont.com/wpjc/wp-json/wp/v2/columns"
        fetch(columnsURL)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    posts: response,
                }, () => {this.afterFetch()})
            })
    }

    afterFetch = () => { if (this.state.posts.length > 0) {
        this.setState({
            activeItem: this.state.posts[0].id 
        })};
    }

    handleItemClick = (postID) => this.setState({ activeItem: postID })

    getSelectedColumn(){
        var out = null;
        this.state.posts.forEach((post) => {
            if (post.id === this.state.activeItem) {
                out =  post;
            }
        })
        return out;
    }

    getPostSummary(post) {
        var cleanText = post.replace(/<\/?[^>]+(>|$)/g, "");
        if (cleanText.length < 210){
            return cleanText
        }

        return cleanText.substring(0,100) + "..."
    }

    render() {
    const { activeItem } = this.state

    return (
        <div>
            <SegmentGroup>
                <Segment style={{
                    marginBottom: '5em',
                    marginTop: '2em',
                    'text-align': 'justify', 'text-justify': 'inter-word'
                }}>
                    <Grid>
                        <Grid.Column width={4}>
                            <Menu fluid size="massive" vertical>
                                { this.state.posts.length === 0 ? (null) : (
                                    this.state.posts.map((post) => {
                                        return (
                                            <Menu.Item 
                                                    name={post.title.rendered} 
                                                    active={activeItem === post.id} 
                                                    onClick={() => {this.handleItemClick(post.id)}}
                                                    key={post.id}
                                                    >
                                                <Header as='h4'>{post.title.rendered}</Header>
                                                <p style={{
                                                    fontSize: 14,
                                                }}>{this.getPostSummary(post.content.rendered)}</p>

                                                <p style={{
                                                    fontSize: 14,
                                                }}><strong>Jorge Caumont</strong></p>
                                            </Menu.Item>
                                        )
                                    })
                                )}
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Segment>
                                <h1>{this.state.activeItem === 0 ? (null) : (this.getSelectedColumn().title.rendered)}</h1>
                                <br/>
                                { this.state.activeItem === 0 ? (null) : (
                                    <div style={{
                                        fontSize: 16,
                                    }} className="content" dangerouslySetInnerHTML={{__html: this.getSelectedColumn().content.rendered}}></div>
                                )}
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Author/>
            </SegmentGroup>
        </div>
        )
    }
}

const Author = () => {
    return (
        <Segment padded='very' vertical>
			<Grid container stackable verticalAlign='middle' celled >
                <Grid.Row>
                    <Grid.Column>
						<Image bordered size='small' src={logo} circular centered/>
						<Header as='h5' textAlign='center' color='grey'>
							Economista Jorge Caumont
						</Header>
					</Grid.Column>
                </Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Header as='h3' style={{ fontSize: '2em' }}>
							Director General
						</Header>
						<p style={{ fontSize: '1em' }}> 
                            <b>Jorge Caumont </b>es Director General del Estudio Jorge Caumont & Asociados. Es Licenciado en Economía por la Facultad de Ciencias Económicas y de Administración de la Universidad de la República y Master of Arts por la Universidad de Chicago. 
                        </p>
                        <p>
                        Desde 2001 es Profesor de Economía del Postgrado en Finanzas de la Universidad ORT. Desde el 1979 
                        hasta el año 2000, fue Profesor grado 5 de Microeconomía Avanzada en la Facultad de Ciencias Económicas 
                        y Administración de la Universidad de la República.
                        </p>
                        <p>
                        Desde 2001 es columnista de Economía & Mercado del diario El País y anteriormente (1979-2000) fue columnista y editorialista del Semanario Búsqueda.
                        </p>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
    )
}