import React, { Component } from 'react';
import {
    Button,
    Form,
    Header,
    Container,
    TextArea,
    Select,
    Image,
    Segment,
    Grid,
    SegmentGroup,
    List,
} from 'semantic-ui-react'
import * as map from './../resources/ubicacion.png'

const options = [
    { key: 'm', text: 'Sr.', value: 'male' },
    { key: 'f', text: 'Sra.', value: 'female' },
    { key: 'd', text: 'Dr.', value: 'doctor'},
]

class ContactPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            contact_form: {
                message: "",
                name: "",
                email: "",
            },
            submitted: false,
        }
        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmitContactForm = this.onSubmitContactForm.bind(this);
    }

    onFormChange(event) {

        let new_form_state = this.state.contact_form
        new_form_state[event.target.id] = event.target.value

        this.setState({
            contact_form: new_form_state,
        })
    }

    onSubmitContactForm() {
        let contactURL = "http://api.jorgecaumont.com/wpjc/wp-json/contact-form-7/v1/contact-forms/58/feedback"

        let formData = new FormData()

        formData.append("your-name", this.state.contact_form.name)
        formData.append("your-email", this.state.contact_form.email)
        formData.append("your-subject", "Consulta")
        formData.append("your-message", this.state.contact_form.message)

        fetch(contactURL, {
            method: 'post',
            body: formData,
        })

        this.setState((prevState) => ({
            ...prevState,
            submitted: true,
        }))
    }

    render() {
        return (
            <div>
                <SegmentGroup>
                    <Segment padded='very'>
                        <Grid container stackable verticalAlign='middle' celled='internally' >
                            <Grid.Row>
                                <Grid.Column  width={8}>
                                    <Image src={map} bordered/>
                                </Grid.Column>
                                <Grid.Column  style={{ fontSize: '1.2em' }} width={8}>
                                    <p>
                                        <h1>Estudio</h1>
                                    </p>
                                    <p>
                                        Ubicado en <strong>Sanlucar 1487 esq. Av Pedro Blanes Viale </strong> (Montevideo 11500, Uruguay)
                                    </p>
                                    <List link>
                                        <Header as='h4' content='Contacto' />
                                        <List.Item>
                                            <List.Icon name='phone'/>
                                            <List.Content>
                                                (+598) 2 600 14 90
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='phone'/>
                                            <List.Content>
                                                (+598) 2 600 02 39
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='phone'/>
                                            <List.Content>
                                                (+598) 99 495 799
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='mail'/>
                                            <List.Content>
                                                jcaumont@jorgecaumont.com
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment style={{
                            marginBottom: '5em',
                            marginTop: '5em',
                        }}>
                        <Container text>
                            <Header> Contactenos </Header>
                            <Form onSubmit={this.onSubmitContactForm}>
                                <Form.Field control={Select} label='Título' options={options} placeholder='Título' />
                                <Form.Input required type="text" label='Nombre' id='name' value={this.state.contact_form.name} onChange={this.onFormChange}/>
                                <Form.Input required type="email" label='Email' id='email' value={this.state.contact_form.email} onChange={this.onFormChange}/>
                                <Form.Field required control={TextArea} label='Consulta' placeholder='Contanos tu consulta' id='message' value={this.state.contact_form.message} onChange={this.onFormChange}/>
                                <Button type='submit' disabled={this.state.submitted}>Enviar</Button>
                            </Form>
                            {!this.state.submitted ? (null) : (
                                <div>
                                    <br/>
                                    <label style= {{color:'green'}}>Su consulta fue enviada, muchas gracias.</label>
                                </div>
                            )}
                        </Container>
                    </Segment>
                    
                </SegmentGroup>
            </div>
        )
    }
}

export default ContactPage;