import React, { Component } from 'react';
import {
    Card,
    Container,
    Segment,
	Image,
	Icon,
	Button,
	Header,
} from 'semantic-ui-react'
import * as color from './../resources/actividadcolor.png'
import { Link } from 'react-router-dom'

const BlurredPicture = () => <Image src={color} fluid />

export default class ActivityPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			posts: [], 
		}
	}

	render() {
		return (
			<div style={{ 'text-align': 'justify', 'text-justify': 'inter-word' }}>
				<BlurredPicture/>
				<Segment style={{
						paddingBottom: '5em',
						paddingTop: '5em',
					}}>
					<Container>
						<Card.Group centered itemsPerRow={3}>
							{Card1}
							{Card2}
							{Card3}
						</Card.Group>
					</Container>
				</Segment>

				<Segment vertical style={{
						paddingBottom: '5em',
						paddingTop: '5em',
					}}>
					<Container textAlign='center' text>
						<Header as='h3' style={{ fontSize: '2em' }}>
							¿Alguna duda?
						</Header>
						<p style={{ fontSize: '1.33em' }}>No dude en contactarnos</p>
						<Button icon labelPosition='right' size='huge' as={Link} to='/contacto' onClick={handleScroll}>
							Enviar Consulta
							<Icon name='angle right' color='black'/>
						</Button>
					</Container>
				</Segment>
			</div>
		)
	}
}

const handleScroll = () => {
    const { index, selected } = this.props
    if (index === selected) {
      const that = this
      setTimeout(() => {
        that.childDiv.current.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
}

const Card1 = (
	<Card>
		<Card.Content>
			<Card.Header style={{ fontSize: '1.83em' }}>Consultoría Macroeconómica</Card.Header>
			<Card.Description>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Análisis de los efectos de las variables externas sobre la economía local
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Proyecciones de las variables
				macroeconómicas locales para la toma
				de decisiones empresariales
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Proyecciones macroeconomicas para fines estratégicos y pruebas de stress
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Modelos de simulación de escenarios
				macroeconómicos para toma de
				decisiones y aprendizaje
				</Segment>
			</Card.Description>
			</Card.Content>
		<Card.Content extra>
		Consultoría Macroeconómica
		</Card.Content>
	</Card>
)

const Card2 = (
	<Card>
		<Card.Content>
			<Card.Header style={{ fontSize: '1.83em' }}>Consultoría Microeconómica</Card.Header>
			<Card.Description>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Estimaciones y proyecciones de
				demanda de productos y servicios
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Estimaciones de costos de
				producción a partir del valor de
				la productividad de los factores
				de producción (trabajo, bienes de
				capital)
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Formulación de políticas de
				precios bajo diversos escenarios
				del mercado (competencia,
				monopolio,etc.)
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Formulación y evaluación de
				proyectos de inversión
				</Segment>
			</Card.Description>
			</Card.Content>
		<Card.Content extra>
		Consultoría Microeconómica
		</Card.Content>
	</Card>
)

const Card3 = (
	<Card>
		<Card.Content>
			<Card.Header style={{ fontSize: '1.83em' }}>Consultoría Financiera</Card.Header>
			<Card.Description>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Determinación de la estructura
				óptima del capital y pasivos
				financieros de la empresa
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Estrategias financieras para
				cobertura de riesgos, especulación
				y arbitraje
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Optimización de los saldos
				ociosos de activos líquidos
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Asesoramiento para la emisión de
				obligaciones negociables
				</Segment>
				<Segment vertical  style={{ fontSize: '1.13em' }}>
				Valuación de empresas y asesoramiento en negociaciones de
				adquisiciones, fusiones, joint
				ventures y acuerdos estratégicos
				</Segment>
			</Card.Description>
			</Card.Content>
		<Card.Content extra>
		Consultoría Financiera
		</Card.Content>
	</Card>
)