import PropTypes from 'prop-types'
import React from 'react'
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  List,
} from 'semantic-ui-react'
import logo from './../resources/foto.png'
import { Link } from 'react-router-dom'
import * as shadow from './../resources/small.png'


const BlurredPicture = () => <Image src={shadow} fluid />
const HomepageHeading = ({ mobile }) => (

	<BlurredPicture/>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

const handleScroll = () => {
    const { index, selected } = this.props
    if (index === selected) {
      const that = this
      setTimeout(() => {
        that.childDiv.current.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
}

const Homepage = () => (
	<div>
		<HomepageHeading/>
		<Segment style={{ padding: '4em 0em', 'text-align': 'justify', 'text-justify': 'inter-word' }} vertical>
			<Grid container stackable verticalAlign='middle'>
				<Grid.Row>
					<Grid.Column width={8}>
						<Header as='h3' style={{ fontSize: '2em' }}>
								Asesoramiento Económico y Financiero
						</Header>
						<p style={{ fontSize: '1.33em' }}> 
							Servicios de asesoramiento económico y financiero 
							a instituciones y empresas del país
							y del exterior.
						</p>
						<Header as='h3' style={{ fontSize: '2em' }}>
							Seminarios de Macroeconomía
						</Header>
						<p style={{ fontSize: '1.33em' }}> 
							Con modelos en programas digitales y para el aprendizaje de productos financieros
							y derivativos modernos.
						</p>
						<br></br>
						<Button icon labelPosition='right' size='huge'  as={Link} to='/actividad' onClick={handleScroll} >
							Más información
							<Icon name='angle right' color='black'/>
						</Button>
					</Grid.Column>
					<Grid.Column floated='right' width={6}>
						<Image bordered rounded size='large' src={logo}/>
						<Header as='h5' textAlign='center' color='grey'>
							Plaza Independencia, Montevideo
						</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column floated='left' width={6}>
		
						<List style={{ fontSize: '1.2em' }}>
							<List.Item as='a'>
								<Icon name='right triangle' />
								<List.Content>
									<List.Header>Confidencialidad</List.Header>
									<List.Description>
										Absoluta reserva de las relaciones con los clientes
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item as='a'>
								<Icon name='right triangle' />
								<List.Content>
									<List.Header>Compromiso</List.Header>
									<List.Description>
										Con cada cliente para la satisfacción plena de sus necesidades
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item as='a'>
								<Icon name='right triangle' />
								<List.Content>
									<List.Header>Excelencia</List.Header>
									<List.Description>
									En la prestación de los servicios que se solicitan
									</List.Description>
								</List.Content>
							</List.Item>
						</List>
					</Grid.Column>
					<Grid.Column floated='right' width={6}>	
						<List style={{ fontSize: '1.2em' }}>
							<List.Item as='a'>
								<Icon name='right triangle' />
								<List.Content>
									<List.Header>Ética de trabajo</List.Header>
									<List.Description>
									Honestidad, dedicación, satisfacción de los trabajos requeridos
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item as='a'>
								<Icon name='right triangle' />
								<List.Content>
									<List.Header>Eficiencia</List.Header>
									<List.Description>
									Eficacia en la prestación de los servicios de la manera más eficiente, propia y para el cliente
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item as='a'>
								<Icon name='right triangle' />
								<List.Content>
									<List.Header>Actualización permanente</List.Header>
									<List.Description>
									Para la mejor prestación de los servicios que brinda el Estudio
									</List.Description>
								</List.Content>
							</List.Item>
						</List>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column textAlign='center'>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	</div>
)

export default Homepage;