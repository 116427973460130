import React, { Component } from 'react';
import './App.css';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import PropTypes from 'react'
import Homepage from './pages/Home/Homepage'
import Contact from './pages/contact/Contact'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import ColumnsPage from './pages/Columns/ColumnsPage';
import ActivityPage from './pages/activity/Activity';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
	const isSSR = typeof window === 'undefined'

	return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* Heads up!
* Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
* It can be more complicated, but you can create really flexible markup.
*/
class DesktopContainer extends Component {
	constructor(props) {
		super(props)
		var url = window.location.pathname
		
		url = url.replace("/", "")
		if (url === ""){
			url = 'inicio'
		}
		console.log(url)
		this.state = {
			activeMenuItem: url
		}
	}

	handleItemClick = (e, { name }) => this.setState({ activeMenuItem: name })

	hideFixedMenu = () => this.setState({ fixed: false })
	showFixedMenu = () => this.setState({ fixed: true })

	render() {
		const { children } = this.props
		const { fixed } = this.state

		return (
		<Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
			<Visibility
			once={false}
			onBottomPassed={this.showFixedMenu}
			onBottomPassedReverse={this.hideFixedMenu}
			>
			<Segment
				inverted
				textAlign='center'
				style={{ minHeight: 90, padding: '1em 0em' }}
				vertical
			>
				<Menu
				fixed={fixed ? 'top' : null}
				inverted={!fixed}
				pointing={!fixed}
				secondary={!fixed}
				size='large'
				>
				<Container style={{ fontSize: '1.1em' }}>
					<Menu.Item name='inicio' active={this.state.activeMenuItem === 'inicio'} onClick={this.handleItemClick} as={Link} to='/'>
					Inicio
					</Menu.Item>
					<Menu.Item name='actividad' active={this.state.activeMenuItem === 'actividad'} onClick={this.handleItemClick} as={Link} to='/actividad'>Actividad</Menu.Item>
					<Menu.Item name='columnas' active={this.state.activeMenuItem === 'columnas'} onClick={this.handleItemClick} as={Link} to='/columnas'>Columnas</Menu.Item>
					<Menu.Item position='right'>
					<Button name='contacto' active={this.state.activeMenuItem === 'contacto'} onClick={this.handleItemClick} as={Link} to='/contacto' inverted={!fixed}>
						Contacto
					</Button>
					</Menu.Item>
				</Container>
				</Menu>
			</Segment>
			</Visibility>

			{children}
		</Responsive>
		)
	}
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
            Inicio
          </Menu.Item>
          <Menu.Item as='a'>Actividad</Menu.Item>
          <Menu.Item as='a'>Columnas</Menu.Item>
          <Menu.Item as='a'>Contacto</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as='a' inverted>
                    Contacto
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}


const handleScroll = () => {
    const { index, selected } = this.props
    if (index === selected) {
      const that = this
      setTimeout(() => {
        that.childDiv.current.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
}

const FooterSegment = () => (
	<Segment inverted vertical style={{ padding: '5em 0em' }}>
		<Container>
			<Grid divided inverted stackable>
				<Grid.Row>
					<Grid.Column width={3}>
						<Header inverted as='h4' content='Sitio' />
						<List link inverted>
							<List.Item as={Link} to='/' onClick={handleScroll}>Inicio</List.Item>
							<List.Item as={Link} to='/actividad' onClick={handleScroll}>Actividad</List.Item>
							<List.Item as={Link} to='/columnas' onClick={handleScroll}>Columnas</List.Item>
							<List.Item><a href='http://api.jorgecaumont.com/wpjc/wp-admin/edit.php?post_type=columns'>Administrar</a></List.Item>
						</List>
					</Grid.Column>
					<Grid.Column width={3}>
						<Header inverted as='h4' content='Consultoría' />
						<List link inverted>
							<List.Item as={Link} to='/actividad' onClick={handleScroll}>Macroeconómica</List.Item>
							<List.Item as={Link} to='/actividad' onClick={handleScroll}>Microeconómica</List.Item>
							<List.Item as={Link} to='/actividad' onClick={handleScroll}>Financiera</List.Item>
						</List>
					</Grid.Column>
					<Grid.Column width={4}>
						<Header as='h4' inverted>
							Estudio
						</Header>
						<List link inverted>
							<List.Item>Sanlucar 1487 (Montevideo 11500, Uruguay)</List.Item>
						</List>
					</Grid.Column>
					<Grid.Column width={3}>
						<List link inverted>
							<Header inverted as='h5' content='Contacto' />
							<List.Item>(+598) 2 600 14 90</List.Item>
							<List.Item>(+598) 2 600 02 39</List.Item>
							<List.Item>(+598) 99 495 799</List.Item>
							<List.Item>jcaumont@jorgecaumont.com</List.Item>
						</List>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</Segment>
)

class App extends Component {
  render() {
    return (
      <Router>
        <ResponsiveContainer/>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/actividad' component={ActivityPage}/>
            <Route path='/contacto' component={Contact}/>
            <Route path='/columnas' component={ColumnsPage}/>
          </Switch>
          <FooterSegment/>
      </Router>
    );
  }
}

export default App;
